<template>
  <notifications classes="vue-notification2 mt-5 text-sm" animation-type="velocity" />
</template>
<style>
.vue-notification2 {
  margin-top: 50px;
  padding: 10px;
  color: #ffffff;

  background: theme("colors.slate.600") !important;
  border-left: 5px solid theme("colors.secondary.400");
  box-shadow: theme("colors.secondary.200");

  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }
}
</style>
