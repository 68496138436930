<script setup>
import BaseIcon from "@/components/BaseIcon.vue";
import { computed, ref } from "vue";
import MainRepository from "@/repositories/MainRepository.js";
import { useRouter } from "vue-router";
import BaseLoading from "@/components/BaseLoading.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import BaseDialog from "@/components/BaseDialog.vue";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  correctQuestions: {
    type: Number,
    required: true,
  },
  incorrectQuestions: {
    type: Number,
    required: true,
  },
  totalQuestions: {
    type: Number,
    required: true,
  },
  publicExamination: {
    type: Number,
    required: true,
  },
  contentLevels: {
    type: Array,
    required: true,
  },
  isFirst: {
    type: Boolean,
    default: false,
  },
  isLast: {
    type: Boolean,
    default: false,
  },
});
const forceNew = true;
const type = "custom";
const dialogOpen = ref(false);
const isNewAttemptExecuting = ref(false);
const maxQuestions = 20;
const roundedClass = computed(() => {
  if (props.isFirst) {
    return "rounded-t-lg";
  }
  if (props.isLast) {
    return "rounded-b-lg";
  }
  return "";
});

const numberOfBlank = computed(() => {
  return Math.max(0, props.totalQuestions - (props.correctQuestions + props.incorrectQuestions));
});

const percentageOfCorrect = computed(() => {
  if (props.totalQuestions > 0) {
    return Math.round((props.correctQuestions / props.totalQuestions) * 100);
  } else {
    return 0;
  }
});

const percentageOfIncorrect = computed(() => {
  if (props.totalQuestions > 0) {
    return Math.round((props.incorrectQuestions / props.totalQuestions) * 100);
  } else {
    return 0;
  }
});

const percentageOfBlank = computed(() => {
  if (props.totalQuestions > 0) {
    return Math.round((numberOfBlank.value / props.totalQuestions) * 100);
  } else {
    return 0;
  }
});

const router = useRouter();

const newAttemptDialog = () => {
  isNewAttemptExecuting.value = false;
  dialogOpen.value = true;
};

const data = {
  title: props.title,
  percentage: props.percentage,
  publicExamination: props.publicExamination,
  contentLevels: props.contentLevels,
  forceNew: forceNew,
  type: type,
  maxQuestions: maxQuestions,
};

async function newAttempt() {
  isNewAttemptExecuting.value = true;
  try {
    const newAttempt = MainRepository.newAttempt();
    const response = await newAttempt(data);
    move(response.data.uuid);
  } catch (error) {
    console.error("Error making the request:", error);
    isNewAttemptExecuting.value = true;
  } finally {
    dialogOpen.value = false;
  }
}

function move(uuid) {
  router.push({ name: "in-attempt", params: { uuid: uuid } });
}
</script>

<template>
  <div class="mb-4 flex items-center rounded-lg bg-background px-5 py-4" :class="roundedClass">
    <div class="flex w-full flex-col">
      <div class="mb-3 flex flex-row justify-between">
        <p class="text-lg font-semibold text-secondary-800">
          {{ title }}
        </p>
        <a @click="newAttemptDialog" class="flex gap-3">
          <base-icon
            icon-name="edit_content_level_statistics"
            class="cursor-pointer"
            title="test personalizado"
          ></base-icon>
        </a>
      </div>

      <p class="mb-3 text-base font-light text-secondary-800">
        {{ description }}
      </p>

      <div class="relative flex items-center">
        <div class="mb-2 flex h-1.5 w-full flex-row rounded-full bg-gray-400">
          <div
            class="h-1.5 rounded bg-green-solid"
            data-tip="{{ percentageOfCorrect }}%"
            :style="{ width: percentageOfCorrect + '%' }"
          />
          <div
            class="tooltip h-1.5 rounded bg-red-600"
            data-tip="{{ percentageOfIncorrect }}%"
            :style="{ width: percentageOfIncorrect + '%' }"
          />
        </div>
      </div>

      <div class="mt-2 flex w-full justify-between">
        <p class="flex gap-1 text-secondary-400">
          <span class="text-green-solid">{{ percentageOfCorrect }}%</span>
          <span>|</span>
          <span class="text-red-600">{{ percentageOfIncorrect }}%</span>
          <span>|</span>
          <span class="text-secondary-400">{{ percentageOfBlank }}%</span>
        </p>
        <p class="flex gap-1 text-secondary-400">
          <span class="mr-3 text-secondary-800">Respuestas:</span>
          <span class="text-green-solid">{{ correctQuestions }}</span>
          <span>|</span>
          <span class="text-red-600">{{ incorrectQuestions }}</span>
          <span>|</span>
          <span class="text-secondary-400">{{ numberOfBlank }}</span>
        </p>
      </div>
    </div>
  </div>
  <base-dialog :is-open="dialogOpen">
    <template v-slot:header>Realizar test preguntas falladas {{ title }}</template>
    <template v-slot:default>
      <div class="mb-2 ml-1 mt-3">
        <p>
          Se creará un test personalizado de hasta {{ maxQuestions }} preguntas para practicar el {{ title }}.<br />
          ¿Está seguro de que desea empezar el test?
        </p>
      </div>
    </template>
    <template v-slot:buttons>
      <base-loading :invisible="!isNewAttemptExecuting" :size="6"></base-loading>
      <button-primary class="mr-2" @click="newAttempt" :disabled="isNewAttemptExecuting">
        <span v-if="!isNewAttemptExecuting">Empezar test</span>
        <span v-else>Creando test...</span>
      </button-primary>
      <button-secondary @click="dialogOpen = false" :disabled="isNewAttemptExecuting"> Cancelar</button-secondary>
    </template>
  </base-dialog>
</template>
