<script setup>
import BaseIcon from "@/components/BaseIcon.vue";
import { computed, ref } from "vue";
import MainRepository from "@/repositories/MainRepository.js";
import { useRouter } from "vue-router";
import BaseLoading from "@/components/BaseLoading.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import BaseDialog from "@/components/BaseDialog.vue";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  percentage: {
    type: Number,
    required: true,
  },
  publicExamination: {
    type: Number,
    required: true,
  },
  contentLevels: {
    type: Array,
    required: true,
  },
  isFirst: {
    type: Boolean,
    default: false,
  },
  isLast: {
    type: Boolean,
    default: false,
  },
});
const forceNew = true;
const type = "custom";
const dialogOpen = ref(false);
const isNewAttemptExecuting = ref(false);
const maxQuestions = 20;
const roundedClass = computed(() => {
  if (props.isFirst) {
    return "rounded-t-lg";
  }
  if (props.isLast) {
    return "rounded-b-lg";
  }
  return "";
});

const router = useRouter();

const newAttemptDialog = () => {
  isNewAttemptExecuting.value = false;
  dialogOpen.value = true;
};

const data = {
  title: props.title,
  percentage: props.percentage,
  publicExamination: props.publicExamination,
  contentLevels: props.contentLevels,
  forceNew: forceNew,
  type: type,
  maxQuestions: maxQuestions,
};

async function newAttempt() {
  isNewAttemptExecuting.value = true;
  try {
    const newAttempt = MainRepository.newAttempt();
    const response = await newAttempt(data);
    move(response.data.uuid);
  } catch (error) {
    console.error("Error making the request:", error);
    isNewAttemptExecuting.value = true;
  } finally {
    dialogOpen.value = false;
  }
}

function move(uuid) {
  router.push({ name: "in-attempt", params: { uuid: uuid } });
}
</script>

<template>
  <div class="border-b">
    <div class="flex items-center bg-background px-5 py-4 hover:bg-background-hover" :class="roundedClass">
      <div class="flex w-full">
        <div class="flex w-1/2 flex-col">
          <p class="text-lg font-semibold text-secondary-800">
            {{ title }}
          </p>
          <p class="mb-2 text-base font-light text-secondary-800">{{ percentage }}% preguntas falladas</p>
          <div class="mb-2 h-1.5 rounded-full bg-gray-400">
            <div class="h-1.5 rounded-full bg-red-600" :style="{ width: percentage + '%' }"></div>
          </div>
        </div>
        <a @click="newAttemptDialog" class="ml-auto cursor-pointer">
          <base-icon icon-name="edit_content_level_statistics" title="test personalizado" />
        </a>
      </div>
    </div>
    <hr class="mx-6 -mt-1 border-t-secondary-400/50" v-if="!isLast" />
  </div>
  <base-dialog :is-open="dialogOpen">
    <template v-slot:header>Realizar test preguntas falladas {{ title }}</template>
    <template v-slot:default>
      <div class="mb-2 ml-1 mt-3">
        <p>
          Se creará un test personalizado de hasta {{ maxQuestions }} preguntas para practicar el {{ title }}.<br />
          ¿Está seguro de que desea empezar el test?
        </p>
      </div>
    </template>
    <template v-slot:buttons>
      <base-loading :invisible="!isNewAttemptExecuting" :size="6"></base-loading>
      <button-primary class="mr-2" @click="newAttempt" :disabled="isNewAttemptExecuting">
        <span v-if="!isNewAttemptExecuting">Empezar test</span>
        <span v-else>Creando test...</span>
      </button-primary>
      <button-secondary @click="dialogOpen = false" :disabled="isNewAttemptExecuting"> Cancelar</button-secondary>
    </template>
  </base-dialog>
</template>
