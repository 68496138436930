<script setup>
import { computed, ref, watch } from "vue";
import QuestionAttemptAnswer from "@/components/attempt-details/QuestionAttemptAnswer.vue";
import BaseTransitionRoot from "@/components/transitions/BaseTransitionRoot.vue";
import BaseCard from "@/components/BaseCard.vue";
import PFavoritaIcon from "@/components/icons/PFavoritaIcon.vue";
import PDudosaIcon from "@/components/icons/PDudosaIcon.vue";
import SubrayarIcon from "@/components/icons/SubrayarIcon.vue";
import PFavoritaHIcon from "@/components/icons/PFavoritaHIcon.vue";
import SubrayarHIcon from "@/components/icons/SubrayarHIcon.vue";
import PDudosaHIcon from "@/components/icons/PDudosaHIcon.vue";

import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
  index: {
    type: [Number, String],
    default: 1,
  },
  id: {
    type: String,
    default: "id-question",
  },
  name: {
    type: String,
    default: "question",
  },
  showIfHighlighted: {
    type: Boolean,
    default: false,
  },
  showIfUncertain: {
    type: Boolean,
    default: false,
  },
  showIfNotAnswered: {
    type: Boolean,
    default: false,
  },
  showFeedback: {
    type: Boolean,
    default: false,
  },
  showResult: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  extraCardClass: {
    type: String,
    default: "",
  },
});

const selectedAnswer = ref(props.question.selectedAnswer);

const show = computed(() => {
  let result = true;
  if (props.showIfUncertain && !props.question.isUncertain) {
    result = false;
  }
  if (props.showIfHighlighted && !props.question.isHighlighted) {
    result = false;
  }
  if (props.showIfNotAnswered && props.question.selectedAnswer != null) {
    result = false;
  }
  return result;
});

const emit = defineEmits(["input", "starred", "uncertain", "highlight", "not-answered", "error"]);

function emitChange(event) {
  if (!props.disabled && !props.showResult) emit(event);
}

function emitError() {
  emit("error");
}

function answerSelected(value) {
  emit("input", value);
}

watch(
  () => props.question.selectedAnswer,
  (first, second) => {
    selectedAnswer.value = first;
  }
);
</script>

<template>
  <BaseTransitionRoot
    :show="show"
    enter="transition-opacity duration-150"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity duration-150"
    leave-from="opacity-100"
    leave-to="opacity-0"
  >
    <div class="flex flex-col">
      <div class="flex-col justify-end">
        <div
          class="relative z-20 float-right -mb-7 mr-6 flex h-14 rounded-md border border-secondary-160 bg-white p-2"
          :class="showResult && !question.isDeprecatedBySync ? 'w-48' : 'w-36'"
        >
          <div class="mr-1 flex h-10 w-10 flex-shrink-0 items-center">
            <p-favorita-h-icon
              v-if="question.isStarred"
              title="marcar como favorita"
              alt="marcar como favorita"
              class="mx-auto cursor-pointer"
              @click.prevent="emitChange('starred')"
            />
            <p-favorita-icon
              v-else
              title="marcar como favorita"
              alt="marcar como favorita"
              class="mx-auto cursor-pointer"
              @click.prevent="emitChange('starred')"
            />
          </div>
          <div class="mr-1 flex h-10 w-10 flex-shrink-0 items-center">
            <p-dudosa-h-icon
              v-if="question.isUncertain"
              title="marcar como dudosa"
              alt="marcar como dudosa"
              class="mx-auto cursor-pointer"
              @click.prevent="emitChange('uncertain')"
            />
            <p-dudosa-icon
              v-else
              title="marcar como dudosa"
              alt="marcar como dudosa"
              class="mx-auto cursor-pointer"
              @click.prevent="emitChange('uncertain')"
            />
          </div>
          <div class="flex h-10 w-10 flex-shrink-0 items-center">
            <subrayar-h-icon
              v-if="question.isHighlighted"
              icon-name="subrayar"
              title="subrayar"
              alt="subrayar"
              class="mx-auto cursor-pointer"
              @click.prevent="emitChange('highlight')"
            />
            <subrayar-icon
              v-else
              icon-name="subrayar"
              title="subrayar"
              alt="subrayar"
              class="mx-auto cursor-pointer"
              @click.prevent="emitChange('highlight')"
            />
          </div>
          <div
            v-show="showResult && !question.isDeprecatedBySync"
            class="flex h-10 w-10 flex-shrink-0 items-center"
            title="Informar de error"
          >
            <exclamation-triangle-icon
              class="mx-auto ml-2 mt-0.5 h-6 cursor-pointer text-secondary-350"
              @click.prevent="emitError()"
            />
          </div>
        </div>
      </div>
      <base-card :id="'question-index-' + index" class="mb-8 w-full scroll-mt-32 p-0 pb-2" :class="extraCardClass">
        <div v-if="question.isDeprecatedBySync" class="mx-10 mt-10 flex flex-row text-base text-lg font-light">
          <div>
            <exclamation-triangle-icon class="mr-2 mt-1.5 h-5 cursor-pointer text-red-200" />
          </div>
          <span class="text-red-200">¡Pregunta anulada! {{ question.deprecatedMessage }}</span>
        </div>
        <div
          class="mx-8 flex rounded-md p-3 text-xl text-secondary-800"
          :class="question.isDeprecatedBySync ? 'mt-5' : 'mt-10'"
        >
          <span class="py-1 font-semibold">{{ index }}.</span>
          <mark
            class="ml-2 px-1 py-1"
            :class="
              question.isHighlighted
                ? 'delay-50 bg-primary-800 transition  duration-300 ease-in-out'
                : 'delay-50 bg-white transition  duration-300 ease-in-out'
            "
            >{{ question.statement }}
          </mark>
        </div>

        <div class="mx-2 p-2">
          <question-attempt-answer
            v-for="(answer, index) in question.answers"
            :id="id + '-answer-' + answer.id"
            :name="name + '-answer'"
            :index="index"
            :key="answer.id"
            :input-value="answer.id"
            v-model="selectedAnswer"
            :label="answer.statement"
            :is-valid="answer.id === question.correctAnswer"
            @update:modelValue="answerSelected(answer.id)"
            :disabled="disabled"
            :show-result="showResult"
          />
        </div>
        <div
          v-if="showFeedback && question.publicExamination.slug !== 'ofimatica-tramitacion-procesal'"
          class="mx-8 my-3 rounded-xl border border-primary-800 bg-background p-5 transition-opacity delay-200 ease-in-out"
        >
          <h4 class="mb-2 text-lg font-medium leading-6 text-secondary-800">Explicación</h4>
          <div class="py-4">
            <div
              class="text-base font-normal leading-normal tracking-normal text-secondary-800"
              v-html="question.feedback"
            ></div>
          </div>
        </div>
        <div
          v-if="question.contentLevels != null && question.contentLevels.length > 0"
          class="mx-10 my-6 text-base font-light text-secondary-800"
        >
          <span v-if="question.contentLevels[0].parentName != null">
            {{ question.contentLevels[0].parentName }}
          </span>
        </div>
        <div v-if="question.publicExaminationWarning" class="mx-10 my-3 text-base font-light text-secondary-800">
          Nota importante: {{ question.publicExaminationWarningContent }}
        </div>
      </base-card>
    </div>
  </BaseTransitionRoot>
</template>
