<script setup>
import { ref } from "vue";
import BaseError from "@/components/BaseError.vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  extraInputClass: {
    type: String,
    default: "",
  },
  url: {
    type: [String, null],
    default: null,
  },
  error: {
    type: [String, Array],
    default: "",
  },
});
const emit = defineEmits(["change"]);
defineExpose({
  reset,
});

const fileInput = ref();

async function handleFile() {
  const file = fileInput.value.files[0];
  emit("change", file);
}

function reset() {
  fileInput.value.value = "";
}

function openDialog() {
  fileInput.value.click();
}
</script>

<template>
  <div>
    <input
      ref="fileInput"
      :id="name"
      :name="name"
      @change="handleFile"
      :disabled="disabled"
      type="file"
      class="cursor-pointer rounded border-2 border-secondary-400 bg-white px-2 py-1 text-secondary-400 file:hidden focus:outline-primary-400 disabled:cursor-not-allowed disabled:bg-secondary-400/50"
      :class="extraInputClass"
    />
    <span class="relative -inset-x-10 -inset-y-0.5 text-secondary-400">|</span>
    <div class="relative -inset-x-8 -inset-y-0.5 inline cursor-pointer" @click="openDialog">
      <slot name="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="inline h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
          />
        </svg>
      </slot>
    </div>
    <base-error :error="error" />
    <slot name="afterInput">
      <small v-if="url !== null" class="block text-secondary-400">
        Archivo subido:
        <a :href="url" class="text-blue-600" target="_blank">
          {{ url }}
        </a>
      </small>
    </slot>
  </div>
</template>

<style scoped></style>
