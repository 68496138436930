<script setup>
import BaseCard from "@/components/BaseCard.vue";
import ExamDetailDummy from "@/components/imported-questions/ExamDetailDummy.vue";
import { computed, inject, onMounted, ref } from "vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import RichTextarea from "@/components/forms/RichTextarea.vue";
import { useRoute, useRouter } from "vue-router";
import { useWS } from "@/components/composables/ws-call.js";
import MainRepository from "@/repositories/MainRepository.js";
import { EXAM_TITLES, yesNoCapitalizedFormatter, capitalizeFirstLetter } from "@/components/utils.js";
import QuestionList from "@/components/imported-questions/QuestionList.vue";
import BaseTextarea from "@/components/forms/BaseTextarea.vue";
import BackButton from "@/components/buttons/BackButton.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import { useToast } from "@/components/composables/notifications.js";
import TitleHeaderView from "@/layout-components/TitleHeaderView.vue";
import BaseSelect from "@/components/forms/BaseSelect.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";
import BaseDialog from "@/components/BaseDialog.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import { DESPUBLICADO, NO_PUBLICADO, PUBLICADO, publishedStateFormatter } from "@/components/exams.js";
import BaseIcon from "@/components/BaseIcon.vue";
import ExamFlow from "@/components/imported-questions/ExamFlow.vue";
import QuestionOppositionSelection from "@/components/imported-questions/QuestionOppositionSelection.vue";
import ExamDetailMinorChangesHelp from "@/components/imported-questions/ExamDetailMinorChangesHelp.vue";
import QuestionListLawMappingError from "@/components/imported-questions/QuestionListLawMappingError.vue";
import ExamDetailMajorChangesHelp from "@/components/imported-questions/ExamDetailMajorChangesHelp.vue";
import ButtonDiscard from "@/components/buttons/ButtonDiscard.vue";

const route = useRoute();
const router = useRouter();
const { sendServerError, sendSuccessNotification } = useToast();

const { callWs, record } = useWS(MainRepository.examDetail(route.params.examId));
const { callWs: update } = useWS(MainRepository.updateExamDetail(route.params.examId));

const onAction = ref(false);

const title = computed(() => {
  if (record.value != null) return EXAM_TITLES[record.value.examType];
  return "";
});

const isPreviousCall = computed(() => {
  return record.value && record.value.examType === "previousCall";
});

const examId = computed(() => {
  return parseInt(route.params.examId);
});
const examCategoryOptions = computed(() => {
  return [
    { label: "ninguna", value: "" },
    { label: "parte general", value: "parte general" },
    { label: "orden civil", value: "orden civil" },
    { label: "orden penal", value: "orden penal" },
    { label: "orden administrativo", value: "orden administrativo" },
    { label: "orden social", value: "orden social" },
  ];
});
const examTitle = computed(() => {
  if (record.value === null) {
    return null;
  }
  if (syncedTitle.value !== "") {
    return syncedTitle.value;
  }
  return record.value.title;
});
const examType = computed(() => {
  if (record.value === null) {
    return "";
  }
  let examTypeLabel = EXAM_TITLES[record.value.examType];
  if (!examTypeLabel) {
    return "";
  }
  if (examTypeLabel.trim() === "") {
    return examTypeLabel;
  }
  return capitalizeFirstLetter(examTypeLabel);
});
const syncedTitle = ref("");

const showStatement = computed(() => {
  if (record.value != null) {
    return record.value.examType !== "previousCall";
  }
  return false;
});
const allowEditStatement = computed(() => {
  if (record.value != null) {
    return record.value.examType !== "allegedPreviousCall";
  }
  return false;
});
const isReviewed = computed(() => {
  if (record.value === null) {
    return false;
  }
  return record.value.reviewed;
});
const numberOfQuestions = computed(() => {
  if (record.value != null) {
    return record.value.numberOfQuestions;
  }
  return 0;
});
const numberOfValidQuestions = computed(() => {
  if (record.value != null) {
    return record.value.numberOfValidQuestions;
  }
  return 0;
});
const numberOfReviewedQuestions = computed(() => {
  if (record.value != null) {
    return record.value.numberOfReviewedQuestions;
  }
  return 0;
});
const wasChangedSincePublication = computed(() => {
  if (record.value != null && wasPublishedAtSomePoint.value) {
    return record.value.changedSincePublication;
  }
  return null;
});
const numberOfAttempts = computed(() => {
  if (record.value != null && wasPublishedAtSomePoint.value) {
    return record.value.numberOfAttempts;
  }
  return 0;
});
const wasPublishedAtSomePoint = computed(() => {
  if (record.value == null) {
    return null;
  }
  return record.value.publishedState !== NO_PUBLICADO;
});
const publishedState = computed(() => {
  if (record.value === null) {
    return "-";
  }
  return publishedStateFormatter(record.value.publishedState);
});
const canPublish = computed(() => {
  if (record.value == null) {
    return null;
  }
  return record.value.publishedState === NO_PUBLICADO;
});

const canRepublish = computed(() => {
  return canRepublishWithModifications.value || canRepublishWithoutModifications.value;
});
const canRepublishWithModifications = computed(() => {
  if (record.value == null) {
    return null;
  }
  return (
    (record.value.publishedState === PUBLICADO || record.value.publishedState === DESPUBLICADO) &&
    record.value.changedSincePublication
  );
});
const canRepublishWithoutModifications = computed(() => {
  if (record.value == null) {
    return null;
  }
  return record.value.publishedState === DESPUBLICADO && !record.value.changedSincePublication;
});

const canDepublish = computed(() => {
  if (record.value == null) {
    return null;
  }
  return record.value.publishedState === PUBLICADO;
});

const canRepublishWithMinorChanges = computed(() => {
  if (record.value == null) {
    return null;
  }
  return (
    (record.value.publishedState === PUBLICADO || record.value.publishedState === DESPUBLICADO) &&
    record.value.changedSincePublication &&
    record.value.canRepublishWithMinorChanges
  );
});

const oppositionIds = computed(() => {
  if (record.value === null) {
    return [];
  }
  return record.value.oppositionsData.map((e) => e.opposition.id);
});

const allowAddOpposition = computed(() => {
  if (record.value == null) {
    return false;
  }
  if (record.value.publishedState === PUBLICADO || record.value.publishedState === DESPUBLICADO) {
    return !record.value.changedSincePublication;
  }
  return true;
});

onMounted(async () => {
  await fetchData();
});

const isPublishExecuting = ref(false);
const isPublishDialogOpen = ref(false);
const adminLawUrl = import.meta.env.VITE_BACKEND_URL + "/wopo-admin/wopoedition/law/";
const lawMappingError = ref(false);

async function publish() {
  lawMappingError.value = false;
  if (record.value === null) {
    return;
  }
  isPublishExecuting.value = true;
  try {
    await MainRepository.publishExam(record.value.id);
    await fetchData();
  } catch (error) {
    sendServerError(error);
    if (error.response?.data?.code === "law_mapping_does_not_exist") {
      lawMappingError.value = true;
    }
    isPublishExecuting.value = false;
  } finally {
    isPublishDialogOpen.value = false;
    // since the modal takes some time if you set isPublishExecuting to false here the user could
    // click the button, so the button is disabled until the dialog is opened again in openPublishDialog
  }
}

const allowPublishToNewOpposition = computed(() => {
  if (record.value === null) {
    return false;
  }

  return record.value.oppositionNames.length !== record.value.oppositionsData.length;
});

const isPublishOnNewOppositionOpen = ref(false);
const isPublishOnNewOppositionExecuting = ref(false);

function openPublishNewOpposition() {
  if (record.value === null) {
    return;
  }
  isPublishOnNewOppositionOpen.value = true;
  isPublishOnNewOppositionExecuting.value = false;
}

const newOppositionNames = computed(() => {
  if (record.value === null) {
    return "";
  }
  // return all oppositions that have no exam id and thus exam is not in those oppositions yet
  return record.value.oppositionsData
    .filter((o) => !o.exam)
    .map((o) => o.opposition.name)
    .join(", ");
});

async function publishToNewsOppositions() {
  if (record.value === null) {
    return;
  }
  isPublishOnNewOppositionExecuting.value = true;
  setExamInOppositionIfMissing();
  try {
    await MainRepository.publishToNewsOppositionsExam(record.value);
    await fetchData();
  } catch (error) {
    sendServerError(error);
  } finally {
    isPublishOnNewOppositionOpen.value = false;
  }
}

function openPublishDialog() {
  isPublishDialogOpen.value = true;
  isPublishExecuting.value = false;
}

const isRemoveExecuting = ref(false);
const isRemoveDialogOpen = ref(false);

function openRemoveDialog() {
  if (record.value === null) {
    return;
  }
  isRemoveDialogOpen.value = true;
  isRemoveExecuting.value = false;
}

async function remove() {
  if (record.value === null) {
    return;
  }
  try {
    isRemoveExecuting.value = true;
    await MainRepository.removeExam(record.value.id);
    if (record.value.examType === "previousCall") {
      await router.push({ name: "exam-list-previous-call" });
    } else if (record.value.examType === "alleged") {
      await router.push({ name: "exam-list-case-study" });
    } else {
      await router.push({ name: "exam-list-case-study-previous-call" });
    }
  } catch (e) {
    sendServerError(e, "QST-LIST");
    isRemoveExecuting.value = false;
  } finally {
    isRemoveDialogOpen.value = false;
    // since the modal takes some time if you set isRemoveExecuting to false here the user could
    // click the button, so the button is disabled until the dialog is opened again in openRemoveDialog
  }
}

const isDepublishDialogOpen = ref(false);
const isDepublishExecuting = ref(false);

async function depublish() {
  if (record.value === null) {
    return;
  }
  isDepublishExecuting.value = true;
  try {
    await MainRepository.depublishExam(record.value.id);
    await fetchData();
  } catch (error) {
    sendServerError(error);
    isDepublishExecuting.value = false;
  } finally {
    isDepublishDialogOpen.value = false;
    // since the modal takes some time if you set isPublishExecuting to false here the user could
    // click the button, so the button is disabled until the dialog is opened again in openPublishDialog
  }
}

function openDepublishDialog() {
  isDepublishDialogOpen.value = true;
  isDepublishExecuting.value = false;
}

const isRepublishWithMinorChangesOpen = ref(false);

function openPublishWithMinorChangesDialog() {
  isRepublishWithMinorChangesOpen.value = true;
  isPublishExecuting.value = false;
}

async function publishWithMinorChanges() {
  if (record.value === null) {
    return;
  }
  isPublishExecuting.value = true;
  try {
    await MainRepository.publishExamWithMinorChanges(record.value.id);
    await fetchData();
  } catch (error) {
    sendServerError(error);
    isPublishExecuting.value = false;
  } finally {
    isRepublishWithMinorChangesOpen.value = false;
    // since the modal takes some time if you set isPublishExecuting to false here the user could
    // click the button, so the button is disabled until the dialog is opened again in openPublishDialog
  }
}

const isRepublishDialogOpen = ref(false);
const isRepublishExecuting = ref(false);

function openRepublishDialog() {
  isRepublishDialogOpen.value = true;
  isRepublishExecuting.value = false;
}

async function republish() {
  if (record.value === null) {
    return;
  }
  isRepublishExecuting.value = true;
  try {
    await MainRepository.republishExam(record.value.id);
    await fetchData();
  } catch (error) {
    sendServerError(error);
    isRepublishExecuting.value = false;
  } finally {
    isRepublishDialogOpen.value = false;
    // since the modal takes some time if you set isPublishExecuting to false here the user could
    // click the button, so the button is disabled until the dialog is opened again in openPublishDialog
  }
}

async function submit() {
  if (!record.value) {
    return;
  }
  onAction.value = true;
  setExamInOppositionIfMissing();
  try {
    await update(record.value);
    await fetchData();
    sendSuccessNotification("Éxito", "Examen ha sido guardado correctamente");
  } catch (e) {
    sendServerError(e, "EXAM-UPD");
  }
  onAction.value = false;
}

async function fetchData() {
  record.value = null;
  try {
    await callWs({});
  } catch (e) {
    sendServerError(e, "EXAM-DET");
  }
}

function setExamInOppositionIfMissing() {
  for (let i = 0; i < record.value.oppositionsData.length; i++) {
    if (!record.value.oppositionsData[i].exam) {
      record.value.oppositionsData[i].exam = examId;
    }
  }
}

function valueUpdated(newValue) {
  record.value.statement = newValue;
}

const metadataDocumentUrl = computed(() => {
  if (record.value.metadata === null) {
    return null;
  }
  return import.meta.env.VITE_BACKEND_URL + record.value.metadata.document.document;
});

const isDocumentationOpen = ref(false);
const isDocumentationPublishOpen = ref(false);

// admin stuff
const user = inject("user");
const adminUrl = import.meta.env.VITE_BACKEND_URL + "/wopo-admin/wopoedition/exam/";
</script>
<template>
  <title-header-view>
    <template v-slot:header>
      <div class="grid grid-cols-2 gap-10">
        <div>
          <p>{{ examTitle }}</p>
          <small v-if="examType" class="text-xl font-normal">
            {{ examType }}
          </small>
        </div>

        <div class="flex flex-row items-center gap-5 justify-self-end text-start text-lg">
          <div>
            <p class="-mb-1">Revisado: {{ yesNoCapitalizedFormatter(isReviewed) }}</p>
            <p class="-mb-1">
              Publicación: {{ publishedState }}
              <base-icon
                title="Ayuda"
                icon-name="question"
                class="inline-block h-8 w-8 cursor-pointer self-end pb-1"
                @click.prevent="isDocumentationOpen = true"
              />
            </p>
          </div>

          <div>
            <p>Preguntas revisadas: {{ numberOfReviewedQuestions }}/{{ numberOfQuestions }}</p>
            <p>Preguntas Revisadas-válidas: {{ numberOfValidQuestions }}/{{ numberOfQuestions }}</p>
          </div>

          <div v-if="wasPublishedAtSomePoint">
            <p>Modificaciones desde la publicación: {{ yesNoCapitalizedFormatter(wasChangedSincePublication) }}</p>
            <p>Nº de intentos de alumnos: {{ numberOfAttempts }}</p>
          </div>
        </div>
      </div>
    </template>
    <base-card class="mb-8">
      <form @submit.prevent="submit">
        <div class="overflow-hidden bg-white">
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg font-medium leading-6 text-secondary-800">Información del Examen</h3>
          </div>
          <fade-transition>
            <div v-if="record == null">
              <exam-detail-dummy />
            </div>
            <div v-else>
              <div class="border-t border-secondary-200">
                <div
                  v-if="user !== null && user.isSuperuser"
                  class="bg-admin px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-secondary-600">Enlace a panel de administración</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <a class="underline" :href="adminUrl + record.id + '/change'">Ver en panel de administración</a>
                  </dd>
                </div>

                <div
                  v-if="record.metadata"
                  class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-secondary-600">Importado de</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <p class="mb-2">
                      <a class="underline" :href="metadataDocumentUrl" target="_blank">
                        {{ record.metadata.document.filename }}
                      </a>
                    </p>
                  </dd>
                </div>

                <div class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-secondary-600">Oposiciones</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <question-opposition-selection
                      v-model="record.oppositionsData"
                      allow-add-message="Para poder añadir oposiciones es necesario que el examen no tenga modificaciones desde la publicación"
                      :select-themes="false"
                      :allow-add="allowAddOpposition"
                      :is-publish="wasPublishedAtSomePoint"
                      :is-in-exam="true"
                    />
                    <div
                      v-if="!wasChangedSincePublication && wasPublishedAtSomePoint && allowPublishToNewOpposition"
                      class="mr-6 flex flex-row content-center justify-end"
                    >
                      <button-secondary
                        class="mb-4 ml-4 flex flex-row content-center gap-2 !py-1"
                        :disabled="onAction"
                        @click.prevent="openPublishNewOpposition"
                      >
                        Publicar en nueva oposición
                      </button-secondary>
                    </div>
                  </dd>
                </div>

                <div class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-secondary-600">Título</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <base-textarea v-model:model-value="record.title" @input="syncedTitle = $event"></base-textarea>
                  </dd>
                </div>

                <div
                  v-if="showStatement"
                  class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-secondary-600">Enunciado</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <rich-textarea
                      :value="record.statement"
                      :disabled="!allowEditStatement"
                      id="statement"
                      @value-updated="valueUpdated"
                    ></rich-textarea>
                  </dd>
                </div>

                <div
                  v-if="showStatement"
                  class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-secondary-600">Categoría</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <div class="fw-full lex mt-1">
                      <base-select
                        v-model="record.examCategory"
                        id="select-exam-category"
                        name="exam-category"
                        :options="examCategoryOptions"
                      />
                    </div>
                  </dd>
                </div>

                <div class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-secondary-600">Orden</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <base-input v-model="record.order" id="order"></base-input>
                  </dd>
                </div>
              </div>

              <div class="mx-5 mt-3 flex flex-row justify-between">
                <div class="flex flex-row content-center gap-2">
                  <base-button v-if="canPublish" @click.prevent="openPublishDialog" :disabled="!record.reviewed">
                    Publicar
                  </base-button>

                  <base-button
                    v-if="canRepublish"
                    @click.prevent="openRepublishDialog"
                    :disabled="onAction || allowPublishToNewOpposition"
                  >
                    Republicar
                  </base-button>

                  <base-button
                    v-if="canDepublish"
                    @click.prevent="openDepublishDialog"
                    :disabled="onAction || allowPublishToNewOpposition"
                  >
                    Despublicar
                  </base-button>

                  <base-button
                    v-if="canRepublish"
                    @click.prevent="openPublishWithMinorChangesDialog"
                    :disabled="onAction || !canRepublishWithMinorChanges"
                  >
                    Republicar con cambios menores
                  </base-button>

                  <base-icon
                    v-if="!canPublish"
                    title="Ayuda"
                    icon-name="question"
                    class="h-8 w-8 cursor-pointer self-end pb-1"
                    @click.prevent="isDocumentationPublishOpen = true"
                  />
                </div>

                <div class="flex flex-row content-center gap-2">
                  <base-loading :invisible="!onAction" :size="6"></base-loading>
                  <button-discard @click.prevent="openRemoveDialog" :disabled="onAction"> Eliminar</button-discard>
                  <base-button :disabled="onAction || (allowPublishToNewOpposition && wasPublishedAtSomePoint)">
                    Guardar
                  </base-button>
                  <back-button class="ml-2" :disabled="onAction">Cancelar</back-button>
                </div>
              </div>

              <question-list-law-mapping-error v-if="lawMappingError" class="mt-3">
                En este momento podrá volver a publicar el examen
              </question-list-law-mapping-error>

              <base-dialog :is-open="isDocumentationOpen">
                <template v-slot:default>
                  <ExamFlow></ExamFlow>
                </template>
                <template v-slot:buttons>
                  <button-primary @click="isDocumentationOpen = false">Entendido</button-primary>
                </template>
              </base-dialog>
              <div class="mx-5 flex flex-row justify-between">
                <div class="pt-3 text-sm font-light">
                  <p v-if="canRepublishWithModifications && !canRepublishWithMinorChanges">
                    Este examen no cumple los criterios para poder republicar con cambios menores.
                  </p>
                  <p v-if="canPublish && record && !record.reviewed">
                    No se puede publicar el exámen porque faltan preguntas por revisar.
                  </p>
                  <p v-if="allowPublishToNewOpposition">
                    Para poder republicar o despublicar el examen es necesario sincronizar o eliminar las oposiciones
                    añadidas.
                  </p>
                </div>
                <p class="pt-3 text-right text-sm font-light">
                  El botón 'Guardar' se utilizará para guardar la información del examen. <br />
                  Las preguntas se guardarán dentro del detalle de cada pregunta.
                </p>
              </div>
            </div>
          </fade-transition>
        </div>
      </form>
    </base-card>
    <question-list
      v-if="record !== null"
      :show-title="false"
      :exam-id="examId"
      :opposition-ids="oppositionIds"
      :show-opposition="false"
      :show-header="false"
      :skip-classes="true"
      is-exam
    />

    <base-dialog :is-open="isPublishDialogOpen">
      <template v-slot:header>¿Está seguro de que quiere publicar el examen?</template>
      <template v-slot:default>
        <p v-if="record">
          Se va a publicar el examen <b>{{ examTitle }}</b> con <b>{{ record.numberOfValidQuestions }} preguntas</b>
          <span v-if="record && record.oppositionNames.length > 1"> en las oposiciones </span>
          <span v-else> en la oposición </span>
          <b>{{ record.oppositionNames.join(", ") }}</b
          >.
        </p>
      </template>
      <template v-slot:buttons>
        <base-loading :invisible="!isPublishExecuting" :size="6"></base-loading>
        <button-primary class="mr-2" @click="publish" :disabled="isPublishExecuting">
          <span v-if="!isPublishExecuting">Publicar</span>
          <span v-else>Publicando...</span>
        </button-primary>
        <button-secondary @click="isPublishDialogOpen = false" :disabled="isPublishExecuting">
          Cancelar
        </button-secondary>
      </template>
    </base-dialog>

    <base-dialog :is-open="isDepublishDialogOpen">
      <template v-slot:header>¿Está seguro de que quiere despublicar el examen?</template>
      <template v-slot:default>
        <p class="mb-1">Esta acción ocultará el examen a los alumnos.</p>
        <p v-if="record">
          Se va a despublicar el examen <b>{{ examTitle }}</b>
          <span v-if="record && record.oppositionNames.length > 1"> en las oposiciones </span>
          <span v-else> en la oposición </span>
          <b>{{ record.oppositionNames.join(", ") }}</b
          >.
        </p>
        <p v-if="record && record.numberOfAttempts > 0" class="mb-1">
          Los alumnos han realizado <b>{{ record.numberOfAttempts }} intentos</b>.
        </p>
        <p v-else class="mb-1">Los alumnos no han realizado ningún intento.</p>
      </template>
      <template v-slot:buttons>
        <base-loading :invisible="!isDepublishExecuting" :size="6"></base-loading>
        <button-primary class="mr-2" @click="depublish" :disabled="isDepublishExecuting">
          <span v-if="!isDepublishExecuting">Despublicar</span>
          <span v-else>Despublicando...</span>
        </button-primary>
        <button-secondary @click="isDepublishDialogOpen = false" :disabled="isDepublishExecuting">
          Cancelar
        </button-secondary>
      </template>
    </base-dialog>

    <base-dialog :is-open="isRepublishDialogOpen">
      <template v-slot:header>¿Está seguro de que quiere republicar el examen?</template>
      <template v-slot:default>
        <exam-detail-major-changes-help v-if="canRepublishWithModifications" />
        <p class="mb-1" v-else>Esta acción mostraŕa el examen a los alumnos de nuevo.</p>
        <p v-if="record && record.numberOfAttempts > 0" class="mb-3">
          Los alumnos han realizado <b>{{ record.numberOfAttempts }} intentos</b>.
        </p>
        <p v-else class="mb-3">Los alumnos no han realizado ningún intento.</p>
      </template>
      <template v-slot:buttons>
        <base-loading :invisible="!isRepublishExecuting" :size="6"></base-loading>
        <button-primary class="mr-2" @click="republish" :disabled="isRepublishExecuting">
          <span v-if="!isRepublishExecuting">Republicar</span>
          <span v-else>Republicando...</span>
        </button-primary>
        <button-secondary @click="isRepublishDialogOpen = false" :disabled="isRepublishExecuting">
          Cancelar
        </button-secondary>
      </template>
    </base-dialog>

    <base-dialog :is-open="isRepublishWithMinorChangesOpen">
      <template v-slot:header>¿Está seguro de que quiere republicar el examen con cambios menores?</template>
      <template v-slot:default>
        <exam-detail-minor-changes-help class="mb-3" />
        <p v-if="record && record.numberOfAttempts > 0" class="mb-3">
          Los alumnos han realizado <b>{{ record.numberOfAttempts }} intentos</b>.
        </p>
        <p v-else class="mb-3">Los alumnos no han realizado ningún intento.</p>
      </template>
      <template v-slot:buttons>
        <base-loading :invisible="!isPublishExecuting" :size="6"></base-loading>
        <button-primary class="mr-2" @click="publishWithMinorChanges" :disabled="isPublishExecuting">
          <span v-if="!isPublishExecuting">Republicar</span>
          <span v-else>Republicando...</span>
        </button-primary>
        <button-secondary :disabled="isPublishExecuting" @click="isRepublishWithMinorChangesOpen = false">
          Cancelar
        </button-secondary>
      </template>
    </base-dialog>

    <base-dialog :is-open="isDocumentationPublishOpen">
      <template v-slot:default>
        <div class="text-left">
          <ul class="ml-5 list-disc">
            <li v-if="canRepublish" class="my-1 mb-2">
              <exam-detail-major-changes-help v-if="canRepublishWithModifications" />
              <p class="mb-1" v-else><b>Republicar</b>: Esta acción mostraŕa el examen a los alumnos de nuevo.</p>
            </li>
            <li v-if="canDepublish" class="my-1 mb-2"><b>Despublicar</b>: se ocultará el examen a los alumnos</li>
            <li v-if="canRepublishWithModifications" class="my-1 mb-2">
              <exam-detail-minor-changes-help />
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:buttons>
        <button-primary @click="isDocumentationPublishOpen = false">Entendido</button-primary>
      </template>
    </base-dialog>

    <base-dialog :is-open="isRemoveDialogOpen" @close_modal="isRemoveDialogOpen = false">
      <template v-slot:header>¿Está seguro de que quiere eliminar el examen?</template>
      <template v-slot:default>
        <p>
          Se va a borrar el examen <b>{{ examTitle }}</b> con <b>{{ numberOfQuestions }}</b> preguntas en
          <span v-if="record && record.oppositionNames.length > 1"> en las oposiciones </span>
          <span v-else> en la oposición </span><b>{{ record.oppositionNames.join(", ") }}</b>
          <span v-if="record.publishedState !== 'not_published'">
            los alumnos han realizado <b>{{ numberOfAttempts }}</b> intentos.
          </span>
          <span v-if="record.publishedState === 'published'">
            <br />El examen <b>se despublicará</b> y luego se eliminará
          </span>
        </p>
      </template>
      <template v-slot:buttons>
        <base-loading :invisible="!isRemoveExecuting" :size="6"></base-loading>
        <button-primary class="mr-2" @click.prevent="remove()" :disabled="isRemoveExecuting">
          <span v-if="!isRemoveExecuting">Borrar</span>
          <span v-else>Borrando...</span>
        </button-primary>
        <button-secondary :disabled="isRemoveExecuting" @click="isRemoveDialogOpen = false">Cancelar</button-secondary>
      </template>
    </base-dialog>

    <base-dialog :is-open="isPublishOnNewOppositionOpen" @close_modal="isPublishOnNewOppositionOpen = false">
      <template v-slot:header>¿Está seguro de que quiere publicar en las nuevas oposiciones?</template>
      <template v-slot:default>
        <p>
          Se va a publicar el examen <b>{{ examTitle }}</b> en las nuevas oposiciones <b>{{ newOppositionNames }}</b>
        </p>
      </template>
      <template v-slot:buttons>
        <base-loading :invisible="!isPublishOnNewOppositionExecuting" :size="6"></base-loading>
        <button-primary
          class="mr-2"
          @click.prevent="publishToNewsOppositions"
          :disabled="isPublishOnNewOppositionExecuting"
        >
          <span v-if="!isPublishOnNewOppositionExecuting">Publicar en nuevas oposiciones</span>
          <span v-else>Publicando...</span>
        </button-primary>
        <button-secondary :disabled="isPublishOnNewOppositionExecuting" @click="isPublishOnNewOppositionOpen = false">
          Cancelar
        </button-secondary>
      </template>
    </base-dialog>
  </title-header-view>
</template>
