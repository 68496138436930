<script setup>
import { inject } from "vue";
import BaseIcon from "@/components/BaseIcon.vue";

const user = inject("user");

defineProps({
  modelValue: {},
  elements: {
    type: Array,
    required: true,
  },
  publicExamination: {
    type: Object,
    required: true,
  },
  titleSelector: {
    type: Function,
    required: true,
  },
});
const emit = defineEmits(["update:modelValue"]);

function selectElement(element) {
  emit("update:modelValue", element);
}
</script>

<template>
  <div class="sm:gid-cols-1 grid items-center gap-4 md:grid-cols-2">
    <div
      v-for="(element, index) in elements"
      :key="index"
      class="relative flex h-full w-full cursor-pointer items-center rounded-lg bg-background px-20 py-2"
      :class="{
        'bg-primary-300': modelValue != null && modelValue.id === element.id,
      }"
      @click.prevent="selectElement(element)"
    >
      <div class="flex w-full flex-col items-center">
        <span class="mt-2 text-lg font-semibold">Examen {{ publicExamination.shortName }}</span>
        <span class="mb-2 text-justify text-base font-light">{{ titleSelector(element) }}</span>
        <div
          v-if="element && user.isSuperuser && element.editionExamId"
          class="mb-2 rounded bg-admin p-2 text-xs font-light"
        >
          Orden {{ element.order }} | N° preguntas: {{ element.numberOfQuestions }} <br class="mb-1" />
          <router-link class="underline" :to="{ name: 'exam-detail', params: { examId: element.editionExamId } }">
            Editar exámen en edición
          </router-link>
        </div>
      </div>

      <div class="absolute right-5">
        <div class="flex w-full flex-col items-center justify-end" :class="{ invisible: !element.markedAsFinished }">
          <base-icon title="Finalizado" icon-name="check-green" class="h-5 w-5" />
          <span class="text-xs text-gray-500">Finaliz.</span>
        </div>
      </div>
    </div>
  </div>
</template>
