<script setup>
import TableWithFilters from "@/components/tables/TableWithFilters.vue";
import MainRepository from "@/repositories/MainRepository.js";
import { EyeIcon } from "@heroicons/vue/24/outline";
import { useRouter } from "vue-router";
import { useToast } from "@/components/composables/notifications.js";
import { computed, inject, onMounted, ref, watch } from "vue";
import {
  ATTEMPT_CUSTOM,
  ATTEMPT_PREVIOUS_CALL,
  ATTEMPT_TYPE_TITLES,
  formatDateTime,
  toHuman,
} from "@/components/utils.js";

const router = useRouter();
const publicExamination = inject("public-examination");

const { sendServerError } = useToast();

const table = ref(null);

const fields = computed(() => [
  {
    label: "Tipo",
    key: "type",
    formatter: (value) => {
      if (value === ATTEMPT_CUSTOM) {
        return "Test personalizado";
      } else if (value === ATTEMPT_PREVIOUS_CALL) {
        return "Test oficial";
      } else {
        ATTEMPT_TYPE_TITLES.find((element) => element.value === value);
      }
    },
  },
  { label: "Finalizado", key: "modifiedAt", formatter: (value) => formatDateTime(value) },
  {
    label: "Tiempo en realizarlo",
    key: "elapsedTime",
    formatter: (value) => toHuman(value),
  },
  { label: "Puntuación", key: "results", formatter: (value) => value.score },
  { label: "Correctas", key: "results", formatter: (value) => value.nCorrectAnswers },
  { label: "Incorrectas", key: "results", formatter: (value) => value.nIncorrectAnswers },
  { label: "En blanco", key: "results", formatter: (value) => value.nNotAnswered },
  { label: "Ver detalle", key: "actions" },
]);

onMounted(async () => {
  if (publicExamination.value != null) {
    try {
      await refreshTable(publicExamination.value);
    } catch (error) {
      sendServerError(error);
    }
  }
});
watch(publicExamination, async (publicExamination) => {
  if (publicExamination != null) {
    await refreshTable(publicExamination);
  }
});

function detail(attempt) {
  router.push({ name: "attempt-results", params: { uuid: attempt.uuid } });
}

function buildParams(publicExamination) {
  return {
    public_examination__slug: publicExamination.slug,
  };
}

async function refreshTable(publicExamination) {
  try {
    await table.value.refreshTable(buildParams(publicExamination));
  } catch (e) {
    sendServerError(e, "ATT-LIST");
  }
}
</script>

<template>
  <div>
    <table-with-filters
      ref="table"
      title="Test realizados"
      :fields="fields"
      :call="MainRepository.attemptList"
      :show-extra-filters="false"
      use-basic-pagination
    >
      <template #cell(actions)="{ row }">
        <div class="flex flex-row">
          <button class="rounded p-1.5" @click="detail(row)" title="consultar">
            <EyeIcon class="block h-5 w-5" />
          </button>
        </div>
      </template>
    </table-with-filters>
  </div>
</template>
