<script setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: {},
  id: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  inputValue: {
    required: true,
  },
  index: {
    type: [Number, String],
    default: 1,
  },
  isValid: {
    type: Boolean,
    default: false,
  },
  showResult: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const currentClass = computed(() => {
  if (props.showResult) {
    if (props.isValid && props.modelValue != null) {
      return "transition-colors delay-200 ease-in-out border border-1 rounded-md border-green-solid bg-green";
    }
    if (props.isValid && props.modelValue == null) {
      return "transition-colors delay-200 ease-in-out border border-1 rounded-md border-blue-solid bg-blue";
    }
    if (props.modelValue === props.inputValue && props.modelValue != null) {
      return "transition-colors delay-200 ease-in-out  border border-1 rounded-md border-red-300 bg-red-100";
    }
  }
  return "border border-2 border-white";
});

const currentIndex = computed(() => String.fromCharCode(65 + props.index));

defineEmits(["update:modelValue"]);
</script>

<template>
  <div class="mx-5 border-b border-b-secondary-160 py-2">
    <div class="flex items-center py-2" :class="currentClass">
      <div
        class="mx-8 flex h-8 w-8 min-w-8 flex-shrink-0 cursor-pointer place-items-center items-center rounded-full"
        :class="{
          'bg-primary-800 text-white hover:bg-opacity-70': modelValue === inputValue && !showResult,
          'border border-circle-6 text-circle-6 hover:bg-secondary-50':
            modelValue !== inputValue && (!showResult || !isValid),
          'bg-red-200 text-white': modelValue === inputValue && showResult && !isValid,
          'bg-green-solid text-white': showResult && isValid,
        }"
        @click.prevent="$emit('update:modelValue', inputValue)"
      >
        <span class="mx-auto">{{ currentIndex }}</span>
      </div>
      <label
        :for="id"
        class="cursor-pointer text-base text-secondary-600"
        :class="{ 'font-bold': modelValue === inputValue }"
      >
        {{ label }}
      </label>
    </div>
  </div>
</template>

<style scoped></style>
