<script setup>
import { useWS } from "@/components/composables/ws-call.js";
import MainRepository from "@/repositories/MainRepository.js";
import { computed, onMounted, ref } from "vue";

import BaseCard from "@/components/BaseCard.vue";
import BaseQuestion from "@/components/imported-questions/BaseQuestion.vue";
import BackButton from "@/components/buttons/BackButton.vue";
import QuestionDummy from "@/components/imported-questions/QuestionDummy.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import { useToast } from "@/components/composables/notifications.js";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";

const props = defineProps({
  questionId: {
    type: Number,
    required: true,
  },
  questionNumber: {
    type: Number,
    required: false,
  },
  isInExamPreview: {
    type: Boolean,
    required: false,
    default: false,
  },
  questionObject: {
    type: Object,
    default: null,
  },
});

const { sendServerError } = useToast();
const { callWs, record } = useWS(MainRepository.questionDetail(props.questionId));

const isCorrectAnswerShown = ref(false);

onMounted(async () => {
  if (props.questionObject !== null) {
    return;
  }
  try {
    await callWs({});
  } catch (e) {
    sendServerError(e, "QST-DET");
  }
});

const question = computed(() => {
  if (props.questionObject !== null) {
    return props.questionObject;
  }
  return record.value;
});
</script>

<template>
  <base-card>
    <fade-transition>
      <div v-if="question === null">
        <question-dummy />
      </div>
      <div v-if="question !== null">
        <div v-if="isInExamPreview && questionNumber !== null" class="text-xl">{{ questionNumber }} .-</div>
        <base-question :question="question" :is-correct-answer-shown="isCorrectAnswerShown" />
      </div>
    </fade-transition>
    <div class="my-3 flex flex-row justify-center">
      <button-secondary v-if="question !== null" @click="isCorrectAnswerShown = !isCorrectAnswerShown">
        <div v-if="!isCorrectAnswerShown">Corrección</div>
        <div v-else>Ocultar</div>
      </button-secondary>
      <back-button v-if="!isInExamPreview" class="ml-2">Volver</back-button>
    </div>
  </base-card>
</template>
